import React, { useState, useEffect } from 'react';

import { OriginalMenuCategory } from 'models/entities/original-menu-category';
import { OriginalDishCategory } from 'models/entities/original-dish-category';
import { Model, ActionName } from './index.model';

import { useAuth } from 'views/services/auth';
import { useModal, ModalResult } from 'views/services/modal';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { ActionsButton } from './views/actions-button';
import { Root, Frame, List, NoItem } from './index.styled';

const OriginalDishCategories = () => {

  const auth = useAuth();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(target: OriginalDishCategory, menuCategory: OriginalMenuCategory) {
    modal.open('edit-original-dish-category', { target, strict: true, other: { menuCategory }, tell: apply });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof OriginalDishCategory)) return;
    const category = result.target as OriginalDishCategory;
    switch (result.state) {
      case 'created': setModel(model.add(category)); break;
      case 'updated': setModel(model.replace(category)); break;
      case 'deleted': setModel(model.remove(category)); break;
    }
  }

  function selectAction(action: ActionName, menuCategory: OriginalMenuCategory) {
    switch (action) {
      case 'create-original-dish-category': return edit(model.build(menuCategory), menuCategory);
    }
  }

  function watchAuth() {
    if (!auth.ok) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  useEffect(watchAuth, [auth.ok]);
  useEffect(watchCollection, [model.collection]);

  return (
    <Root>
      <Header mode={auth.user.admin ? 'admin' : ''} />
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <Frame>
          {!model.list.length ? <NoInterfaceData>No Original Menu Category</NoInterfaceData> : model.list.map((a, i) => (
            <List key={i}>
              <table>
                <thead>
                <tr>
                  <th>{a.name}</th>
                  <th>
                    <ActionsButton onSelect={action => selectAction(action, a)} />
                  </th>
                </tr>
                </thead>
              </table>
              <table>
                <tbody>
                {a.dishCategories.documents.map((b, j) => (
                  <tr key={j} onClick={() => edit(b, a)}>
                    <td>{b.name}</td>
                    <td>{b.offMenu && 'Off-menu'}</td>
                  </tr>
                ))}
                {!a.dishCategories.documents.length && (
                  <tr className="no-item">
                    <td colSpan={2} className="no-item">
                      <NoItem>No Original Dish Category</NoItem>
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </List>
          ))}
        </Frame>
      )}
    </Root>
  );

};

export default OriginalDishCategories;