import { OriginalMenuCategory, OriginalMenuCategoryCollection } from 'models/entities/original-menu-category';
import { OriginalDishCategory } from 'models/entities/original-dish-category';

import { Condition } from './models/condition';

class Model {

  readonly condition: Condition;
  readonly collection?: OriginalMenuCategoryCollection;
  readonly list?: OriginalMenuCategory[];

  constructor() {
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const collection = await OriginalMenuCategoryCollection.read();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await (await this.collection.readAll()).readAllDishCategories();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  build(menuCategory: OriginalMenuCategory): OriginalDishCategory  {
    if (!this.collection) throw new Error('collection is undefined');
    return OriginalDishCategory.buildNext(menuCategory.dishCategories.documents, { menuCategoryId: menuCategory.id! });
  }

  add(category: OriginalDishCategory ): this {
    if (!this.collection) throw new Error('collection is undefined');
    const menuCategory = this.collection.documents.find(it => it.id === category.menuCategoryId)!.add(category);
    return this.apply(menuCategory);
  }

  replace(category: OriginalDishCategory ): this {
    if (!this.collection) throw new Error('collection is undefined');
    const menuCategory = this.collection.documents.find(it => it.id === category.menuCategoryId)!.replace(category);
    return this.apply(menuCategory);
  }

  remove(category: OriginalDishCategory ): this {
    if (!this.collection) throw new Error('collection is undefined');
    const menuCategory = this.collection.documents.find(it => it.id === category.menuCategoryId)!.remove(category);
    return this.apply(menuCategory);
  }

  private apply(menuCategory: OriginalMenuCategory): this {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = this.collection.replace(menuCategory);
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

}

export { Model };

export * from './models/action-name';