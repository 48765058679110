import styled from 'styled-components';

import { baseFontFamily, baseFontSize, baseLineHeight } from 'views/services/font-scheme';
import { baseColor, darkBaseColor, textColor, lightTextColor, lineColor, lightPrimaryColor, darkPrimaryColor, vividPrimaryColor, primaryTextColor, primaryColor, accentColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

export const Root = styled.div`
`;

export const Frame = styled.div`
  width: 100vw;
  height: calc(100vh - ${headerHeight});
  overflow: scroll;
  background-color: ${darkBaseColor};
`;

export const List = styled.div`
  & > table {
    width: 100%;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    line-height: ${baseLineHeight};
    background-color: ${baseColor};
    color: ${textColor};
    border-collapse: collapse;
    &:first-child {
      z-index: 10;
      position: sticky;
      top: 0;
    }
    & > thead {
      & > tr {
        & > th {
          height: ${headerHeight};
          text-align: left;
          vertical-align: middle;
          font-weight: bold;
          padding: 0 16px;
          background-color: ${darkPrimaryColor};
          color: ${primaryTextColor};
          &:nth-child(1) {
            width: calc(100% - 48px);
          }
          &:nth-child(2) {
            width: 48px;
            padding: 0;
          }
        }
      }
    }
    & > tbody {
      & > tr {
        &:not(.no-item):hover {
          cursor: pointer;
          background-color: ${lightPrimaryColor};
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: -0.5px;
            left: 0;
            right: 0;
            bottom: -0.5px;
            border-top: 1px solid ${vividPrimaryColor};
            border-bottom: 1px solid ${vividPrimaryColor};
          }
        }
        & > td {
          &:not(.no-item):nth-child(1) {
            width: 50%;
          }
          &:nth-child(2) {
            width: 50%;
          }
          border-top: 1px solid ${lineColor};
          border-bottom: 1px solid ${lineColor};
          padding: 8px 16px;
          &.limiting {
            max-width: 160px;
            padding-right: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;

export const NoItem = styled.div`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 2);
  color: ${lightTextColor};
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

